<script setup lang="ts">
</script>

<template>
   <footer>
      <div class="footer pc_footer">
        <h1 class="footer_logo">
          <img src="/resources/images/logo_white.png" alt="푸터로고">
        </h1>
        <div class="siteinfo">
          <address>
            <ul>
              <li><strong>서울 본사</strong> : 서울특별시 서초구 반포대로30길 81, 웅진타워 7층, 8층 (TEL: 02-525-1025 / FAX: 02-525-3152)</li>
              <li><strong>세종 지사</strong> : 세종특별자치시 국세청로 32, 마크원애비뉴 A동 9층 (TEL: 044-715-5447 / FAX: 044-715-5448)</li>
              <li><strong>부산 지사</strong> : 부산광역시 동구 중앙대로 240, 현대해상화재보험빌딩 5층 (TEL: 051-468-7011 / FAX: 051-468-7012)</li>
            </ul>
          </address>
          <p class="copyright">Copyright 2024. 한국녹색기후기술원 All Rights Reserved.</p>
        </div>
        <div class="col_area">
          <ul class="add">
            <!-- <li><a href="/policy/policy01" target="_blank">개인정보처리 방침</a></li> -->
            <!-- <li><a href="/policy/policy02" target="_blank">저작권 보호 정책</a></li> -->
            <li><a href="/policy/policy03" target="_blank">이메일 무단 수집 거부</a></li>
          </ul>
          <div class="family">
            <a href="#none" class="btn_family" title="관련 사이트 보기">관련 사이트</a>
            <ul class="family_list">
              <li><a href="https://kolas.kgct.or.kr/" target="_blank" title="한국에너지공단 새창열림">SW 시험인증센터</a></li>
              <li><a href="http://www.molit.go.kr/portal.do" target="_blank" title="국토교통부 새창열림">국토교통부</a></li>
              <li><a href="https://www.moe.go.kr/" target="_blank" title="교육부 새창열림">교육부</a></li>
              <li><a href="http://www.motie.go.kr/www/main.do" target="_blank" title="산업통상자원부 새창열림">산업통상자원부</a></li>
              <li><a href="http://me.go.kr/home/web/main.do" target="_blank" title="환경부 새창열림">환경부</a></li>
              <li><a href="https://www.seoul.go.kr/main/index.jsp" target="_blank" title="서울특별시 새창열림">서울특별시</a></li>
              <li><a href="https://www.energy.or.kr/" target="_blank" title="한국에너지공단 새창열림">한국에너지공단</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="footer m_footer">
        <h1 class="footer_logo"><img src="/resources/images/logo_white.png" alt="푸터로고"></h1>
        <ul class="add">
          <!-- <li><a href="/policy/policy01" target="_blank">개인정보처리 방침</a></li> -->
          <!-- <li><a href="/policy/policy02" target="_blank">저작권 보호 정책</a></li> -->
          <li><a href="/policy/policy03" target="_blank">이메일 무단 수집 거부</a></li>
        </ul>
        <div class="siteinfo">
          <address>
            <ul>
              <li><strong>서울 본사</strong> : 서울특별시 서초구 반포대로30길 81, 웅진타워 7층, 8층<br>
                (TEL: 02-525-1025 / FAX: 02-525-3152)
              </li>
              <li><strong>세종 지사</strong> : 세종특별자치시 국세청로 32, 마크원애비뉴 A동 9층<br>
                (TEL: 044-715-5447 / FAX: 044-715-5448)
              </li>
              <li><strong>부산 지사</strong> : 부산광역시 동구 중앙대로 240, 현대해상화재보험빌딩 5층<br>
                (TEL: 051-468-7011 / FAX: 051-468-7012)
              </li>
            </ul>
          </address>
        </div>
        <div class="col_area">
          <div class="family">
            <a href="#none" class="btn_family" title="관련 사이트 보기">관련 사이트</a>
            <ul class="family_list">
              <li><a href="http://www.molit.go.kr/portal.do" target="_blank" title="국토교통부 새창열림">국토교통부</a></li>
              <li><a href="https://www.moe.go.kr/" target="_blank" title="교육부 새창열림">교육부</a></li>
              <li><a href="http://www.motie.go.kr/www/main.do" target="_blank" title="산업통상자원부 새창열림">산업통상자원부</a></li>
              <li><a href="http://me.go.kr/home/web/main.do" target="_blank" title="환경부 새창열림">환경부</a></li>
              <li><a href="https://www.seoul.go.kr/main/index.jsp" target="_blank" title="서울특별시 새창열림">서울특별시</a></li>
              <li><a href="https://www.energy.or.kr/" target="_blank" title="한국에너지공단 새창열림">한국에너지공단</a></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
</template>